import React from "react"
import './animated_index.css'

export default function IndexAnimated({ data }) {
  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column text-center tint-video-bg text-white">
      <video className="bgvideo" loop="loop" muted playsInline src="../images/hype-bg.mp4"  autoPlay></video>
      <header className="mb-auto">
        <div>
          <h3 className="float-md-start mb-0"></h3>
          <nav className="nav nav-masthead justify-content-center float-md-end">
            <a className="nav-link" aria-current="page" href="blog">Blog</a>
            <a className="nav-link" href="https://chat.whatsapp.com/DWIEPNVsLzP2ixSmLI0R2f">Join us</a>
            <a className="nav-link" href="https://t.me/wineredskull">Contact</a>
          </nav>
        </div>
      </header>

      <main className="px-3 w-75 mx-auto">
        <h1>Berlin action boys</h1>
        <p className="lead">
          No subscriptions, no registration and no bullshit. Just friends hanging out.
        </p>
        <p className="lead">
          <a href="https://chat.whatsapp.com/DWIEPNVsLzP2ixSmLI0R2f" className="btn btn-lg btn-secondary fw-bold border-white">Join the family!</a>
        </p>
      </main>

      <footer className="mt-auto text-white-50 tint-video-bg">
        <p><a target="_blank" href="https://roman-guivan.online/" className="text-white">Roman</a>&nbsp;made this</p>
      </footer>
    </div>
  )
}